import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const TextContent = () => {
  const downloadFile = useStaticQuery(graphql`
    query QueryDownloadCT {
      file(relativePath: { eq: "CT-Service.xlsx" }) {
        publicURL
        name
      }
    }
  `)
  return (
    <section
      className="elementor-element elementor-element-37bac58 elementor-section-stretched elementor-section-height-min-height elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section"
      data-id="37bac58"
      data-element_type="section"
      data-settings='{"stretch_section":"section-stretched"}'
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-cc813c4 elementor-column elementor-col-100 elementor-top-column"
            data-id="cc813c4"
            data-element_type="column"
            data-settings='{"":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-9cecdef elementor-widget__width-inherit elementor-widget elementor-widget-heading"
                  data-id="9cecdef"
                  data-element_type="widget"
                  data-settings='{"":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h1
                      className="elementor-heading-title elementor-size-default title-size"
                      id="padding-title-main"
                    >
                      Clinical Trials
                    </h1>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-04d601f elementor-widget__width-inherit elementor-widget elementor-widget-heading"
                  data-id="04d601f"
                  data-element_type="widget"
                  data-settings='{"":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container padding-content">
                    Our services includes monitoring and project management. We
                    provide regular ICH-GCP training for our CRAs to ensure the
                    highest working standards. They are very cooperative, having
                    good relationships with the sites, ensure to follow up the
                    projects consistently and up-to-date with the latest
                    development. Our CRAs are based in Indonesia, very familiar
                    with local condition in Indonesia and very capable to
                    facilitates communication between study sites and the
                    sponsor's study team.
                    {/* <p>To find out more about our Clinical Trial services,</p>
                    <a
                      className="button"
                      id="warnaButton"
                      // href={`CT-Service.xlsx`}
                      href={downloadFile.file.publicURL}
                      download
                    >
                      Click Here{" "}
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextContent
