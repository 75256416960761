import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ImgRendering from 'gatsby-image';

export default ({ pmlImg }) => (
	<StaticQuery
		query={graphql`
			{
				berhasil: allFile(filter: { absolutePath: { regex: "/images/" } }) {
					edges {
						node {
							relativePath
							childImageSharp {
								id
								fluid(quality: 90) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		`}
		render={(data) =>
			data.berhasil.edges.map(
				({ node }) =>
					node.relativePath.indexOf(pmlImg) !== -1 ? (
						<ImgRendering fluid={node.childImageSharp.fluid} />
					) : null
			)}
	/>
);
